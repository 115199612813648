import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  View,
  ScrollView,
  Platform,
  StyleSheet,
  Dimensions,
} from "react-native";
import _ from "lodash";
import ThemeContext from "@presto-contexts/ThemeContext";
import LoadingContainer from "@presto-components/LoadingContainer";
import PaymentManager from "@presto-services/features/payment/PaymentManager";
import OrderManager from "@presto-services/features/orders/OrderManager";
import utils from "../../utils";
import CartContext from "@presto-contexts/CartContext";
import AppConfig from "@presto-common/config";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import RazorpayCheckout from "react-native-razorpay";
import UserContext from "@presto-contexts/UserContext";
import I18n from "i18n-js";
import PrestoText from "@presto-components/PrestoText";
import { VerticalSpacing } from "@presto-components/Spacing";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import { useAssets } from "presto-react-components";
import TitleHeaderWithBackAndRightIcons from "@presto-components/Headers/TitleHeaderWithBackAndRightIcons";
import WalletManager from "@presto-services/features/wallet/WalletManager";
import useFocusEffect from "@presto-common/useFocusEffect";
import CheckboxRowButton from "@presto-components/CheckBoxes/CheckboxRowButton";
import MissingInformation from "@presto-screens/MissingInformation/MissingInformation";
import ModalContext from "@presto-contexts/ModalContext";

export default function PaymentOptions(props) {
  const { address: addressParam, giftingDetails } = props?.route?.params;
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  const styles = getStyles(theme);
  const { user, currentAddress } = useContext(UserContext);
  const { cart, placeOrder, checkoutCart, refreshCart } = useContext(
    CartContext
  );
  const [loading, setLoading] = useState(false);
  const [wallet, setWallet] = useState(undefined);
  const [paymentOption, setPaymentOption] = useState("WALLET");
  const { Navigator } = useContext(NavigatorContext);
  const windowWidth = Dimensions.get("window").width;
  let address = addressParam || currentAddress;
  const { showRightModal, popRightModal } = useContext(ModalContext);

  useFocusEffect(
    useCallback(() => {
      setLoading(true);
      WalletManager.getWalletInfo(
        { walletId: user?.wallet_id },
        (response) => {
          let walletObject = response.data;
          walletObject.current_balance = walletObject?.current_balance
            ? Math.round(walletObject?.current_balance / 100)
            : 0;

          setWallet(walletObject);
          setLoading(false);
        },
        () => {}
      );
      setLoading(false);
    }, [user])
  );

  useEffect(() => {
    if (wallet?.current_balance >= cart?.total_price / 100.0)
      setPaymentOption("WALLET");
    else setPaymentOption("ONLINE");
  }, [wallet, user]);

  let paymentOp = [
    {
      name: I18n.t("screen_messages.wallet.wallet_text"),
      value: "WALLET",
      description: `Wallet Balance  ₹ ${wallet?.current_balance || 0}`,
      icon: (
        <svgs.WalletFilledIcon
          color={theme.secondaryTextColor}
          width={30}
          hight={30}
        />
      ),
    },
    {
      name: I18n.t("screen_messages.payments.online_text"),
      value: "ONLINE",
      description: "Credit Card/Debit Card/Netbanking",
      icon: (
        <svgs.OnlineV2 color={theme.secondaryTextColor} width={30} hight={30} />
      ),
    },
  ];

  const goBack = () => {
    if (utils.isWeb()) popRightModal();
    else Navigator.emit({ event: "goBack" });
  };

  const onSuccessPayment = (id) => {
    if (Platform.OS !== "web") {
      Navigator.emit({
        event: "jumpTab",
        params: {
          tabName: "HOME",
          screenName: "HOME_SCREEN",
        },
      });
    }

    setTimeout(() => {
      Navigator.emit({
        event: "modal",
        params: {
          nextUrl: {
            pathname: "/",
            query: {
              showThanks: "true",
              orderId: id,
            },
          },
          screenName: "ORDER_SUCCESS_SCREEN",
          screenParams: { orderId: id },
        },
      });
    }, 500);
  };

  const onPressPaymentOption = (option) => {
    if (
      option?.value === "WALLET" &&
      wallet?.current_balance < cart?.total_price / 100.0
    ) {
      alert(I18n.t("screen_messages.low_balance"));
    } else {
      setPaymentOption(option.value);
    }
  };

  const isGuestUser = () => {
    return user?.name == "Guest";
  };

  const isGuestEmail = () => {
    return _.startsWith(user?.email, `${user?.phone_number}@`);
  };

  const goToMissingInformation = () => {
    if (utils.isMobile()) {
      Navigator.emit({
        event: "modal",
        params: {
          screenName: "MISSING_INFORMATION",
        },
      });
    } else {
      showRightModal(<MissingInformation route={{ params: {} }} />);
    }
  };

  const onPressPay = async () => {
    if (paymentOption != null) {
      if (isGuestEmail() && isGuestUser()) {
        goToMissingInformation();
      } else {
        setLoading(true);
        const [error, response] = await checkoutCart({
          address: address,
          instructions: giftingDetails ? JSON.stringify(giftingDetails) : "",
          payment_type: paymentOption != "COD" ? "ONLINE" : paymentOption,
          delivery_type: "Delivery",
          other_data: {},
          source: "MOBILE",
        });
        if (error) {
          console.log(
            I18n.t("error_messages.error_checking_out"),
            error,
            response
          );
          alert(I18n.t("error_messages.error_checking_out"));
        } else {
          onPlaceOrder(paymentOption, response.data);
        }
      }
    }
  };

  const onPlaceOrder = async (payment, cart) => {
    const [error, response] = await placeOrder({});
    if (error) {
      alert(utils.errorMessage(error));
      setLoading(false);
    } else {
      if (payment === "WALLET") {
        makePaymentWithoutPin(response.data, cart);
      } else if (payment === "ONLINE") {
        handleOnlinePayment(response.data, cart);
      } else {
        setLoading(false);
        OrderManager.getOrder(
          { id: response?.data?.id },
          async (response) => {
            const orderDetails = await response.data;
            setTimeout(() => {
              onSuccessPayment(orderDetails?.friendly_id);
            }, 500);
          },
          (error) => {
            alert(
              I18n.t("screen_messages.common.sry_text"),
              utils.errorMessage(error)
            );
          }
        );
      }
    }
  };

  const makePaymentWithoutPin = (order, cart) => {
    const params = {
      id: order.id,
      id_type: "order_id",
    };
    setLoading(true);
    WalletManager.makePayment(
      params,
      (response) => {
        console.log("payment response : ", response);
        setLoading(false);
        OrderManager.getOrder(
          { id: order?.id },
          async (response) => {
            const orderDetails = await response.data;
            setTimeout(() => {
              onSuccessPayment(orderDetails?.friendly_id);
            }, 500);
          },
          (error) => {
            alert(
              I18n.t("screen_messages.common.sry_text"),
              utils.errorMessage(error)
            );
          }
        );
      },
      (error) => {
        setLoading(false);
        console.log("Error Payment Wallet", error, utils.errorMessage(error));
        alert(
          I18n.t("screen_messages.common.sry_text"),
          utils.errorMessage(error)
        );
      }
    );
  };

  const handleOnlinePayment = (order, cart) => {
    var params = {};
    setLoading(true);
    params.id_type = "order_id";
    params.id = order.id;
    params.user = user;
    params.total_amount = cart.total_price;
    PaymentManager.initiateRazorPayPaymentV1(
      params,
      (response) => {
        const data = response.data.payment_response.payment_data;
        var paymentConfig = AppConfig.payment_config.razor_pay;
        const payment_data = response.data.payment_response;
        var options = {
          description: "Payment",
          image: paymentConfig.merchant_logo_path,
          currency: paymentConfig.currency,
          key: paymentConfig.razor_pay_api_key,
          amount: data.total_amount,
          name: paymentConfig.app_name,
          prefill: {
            email: data.user_email,
            contact: data.user_phone_number,
            name: data.user_name,
          },
          theme: { color: paymentConfig.razor_pay_theme_color },
          order_id: data.order_id,
        };
        if (Platform.OS === "web") {
          options.handler = (resp) => {
            paymentSuccess(resp, order, cart, payment_data);
          };
          options.modal = {
            ondismiss: function () {
              refreshCart();
              setLoading(false);
            },
          };
          var rzp1 = new Razorpay(options);
          rzp1.open();
          rzp1.on("payment.failed", function (error) {
            console.log("failed ", error);
            paymentFail(error);
          });
        } else {
          initiateRazorPay(order, cart, data, payment_data);
        }
      },
      (error) => {
        console.log("handleOnlinePayment error -", error);
      }
    );
  };

  const paymentFail = (response) => {
    setLoading(false);
    alert(
      I18n.t("screen_messages.payments.failed_title"),
      I18n.t("screen_messages.payments.failed_description")
    );
    console.log("this is failed", response);
  };

  const paymentSuccess = (response, order, cart, payment_data) => {
    console.log("", order);
    var params = {};
    params.paymentResponse = response;
    params.razorPayUrl = payment_data.razor_post_url;

    PaymentManager.captureRazorPayPaymentV1(
      params,
      (resp) => {
        setLoading(false);
        refreshCart();
        OrderManager.getOrder(
          { id: order?.id },
          async (response) => {
            const orderDetails = await response.data;
            setTimeout(() => {
              onSuccessPayment(orderDetails?.friendly_id);
            }, 500);
          },
          (error) => {
            alert(
              I18n.t("screen_messages.common.sry_text"),
              utils.errorMessage(error)
            );
          }
        );
        //goToNextScreen(order, cart);
      },
      (error) => {
        setLoading(false);
        console.log("capture error -", error);
        paymentFail(error);
      }
    );
  };

  const initiateRazorPay = (order, cart, data, payment_data) => {
    var paymentConfig = AppConfig.payment_config.razor_pay;
    var options = {
      description: "Payment",
      image: paymentConfig.merchant_logo_path,
      currency: paymentConfig.currency,
      key: paymentConfig.razor_pay_api_key,
      amount: data.total_amount,
      name: paymentConfig.app_name,
      prefill: {
        email: data.user_email,
        contact: data.user_phone_number,
        name: data.user_name,
      },
      theme: { color: paymentConfig.razor_pay_theme_color },
      order_id: data.order_id,
    };
    RazorpayCheckout.open(options)
      .then((response) => {
        paymentSuccess(response, order, cart, payment_data);
      })
      .catch((error) => {
        paymentFail(error, order, cart, payment_data);
      });
  };
  
  return (
    <LoadingContainer
      bgColor="transaparent"
      style={{ backgroundColor: theme.appBackgroundColor }}
      loading={loading}
    >
      <TitleHeaderWithBackAndRightIcons
        title={I18n.t("screen_messages.payment_text")}
        onPressBack={() => goBack()}
      />
      <ScrollView contentContainerStyle={{ paddingBottom: 150 }}>
        <View>
          <PrestoText
            fontStyle="600.semibold"
            color={theme.title}
            extraStyle={{ padding: theme.containerPadding }}
          >
            {I18n.t("screen_messages.payment_type")}
          </PrestoText>
          <View>
            {paymentOp.map((option) => (
              <>
                <CheckboxRowButton
                  title={option.name}
                  description={option.description}
                  icon={option.icon}
                  showDisclosure={true}
                  selected={option.value === paymentOption}
                  onPress={() => onPressPaymentOption(option)}
                  titleColor={theme.black}
                  descriptionColor={theme.subHeader}
                  paddingLeft={theme.containerPadding}
                  paddingHorizontal={10}
                  borderWidth={1}
                  borderColor={
                    option.value === paymentOption
                      ? theme.primary
                      : theme.subHeader
                  }
                  selectedIconColor={theme.primary}
                  height={80}
                />
                <LineDivider
                  dividerColor="#DADADA"
                  width="100%"
                  theme={theme}
                />
              </>
            ))}
          </View>
        </View>
      </ScrollView>
      <View style={styles.floatingContainer}>
        <VerticalSpacing size={10} />
        <View style={styles.floatingBody}>
          <PrestoSolidButton
            size="large"
            title={I18n.t("screen_messages.button_cancel")}
            buttonStyle="tertiary"
            bgColor={theme.grey}
            onPress={goBack}
            titleColor={theme.white}
            width={windowWidth / 2.3}
            titleFontStyle="600.semibold"
            titleSize={18}
          />
          <PrestoSolidButton
            size="large"
            title={I18n.t("screen_messages.notifications.pay")}
            buttonStyle="primary"
            onPress={onPressPay}
            titleColor={theme.white}
            width={windowWidth / 2.3}
            titleFontStyle="600.semibold"
            titleSize={18}
          />
        </View>
      </View>
    </LoadingContainer>
  );
}

function getStyles(theme) {
  return {
    scrollViewContainer: {
      flex: 1,
      width: "100%",
      height: "100%",
    },
    paddingH20: {
      paddingHorizontal: theme.containerPadding,
    },
    paddingH15: {
      paddingHorizontal: theme.primaryPadding,
    },
    floatingContainer: {
      width: "100%",
      height: 80,
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: theme.white,
      flex: 1,
    },
    floatingBody: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-around",
      paddingHorizontal: 10,
    },
  };
}
