import React, { useContext } from "react";
import { View, Pressable, Platform } from "react-native";
import TouchableWithoutFeedback from "@presto-components/TouchableWithoutFeedback/TouchableWithoutFeedback";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import PrestoIcon from "@presto-components/PrestoIcon";
import PrestoText from "@presto-components/PrestoText";
import { useSafeArea } from "react-native-safe-area-context";
import { useAssets } from "presto-react-components";
import utils from "../../utils";
import ModalContext from "@presto-contexts/ModalContext";

export default function TitleHeaderWithBackAndRightIcons(props) {
  const {
    title,
    count,
    showCartIcon,
    showFilterIcon,
    leftIcon,
    onPressLeftIcon,
    showHomeIcon,
    onPressCart,
    showSelectedFilterIcon,
    onPressBack,
    showBack,
    borderBottomColor,
    goBack,
  } = props;
  const { theme } = useContext(ThemeContext);
  const { svgs } = useAssets();
  const { Navigator } = useContext(NavigatorContext);
  const insets = useSafeArea();
  const isDesktop = utils.isDesktop();
  const { popRightModal, hideRightModal } = useContext(ModalContext);
  const isMobileWeb = utils.isMobileWeb();
  const isNextJsApp = utils.isNextJsApp();
  const isWeb = utils.isWeb();

  const onBack = () => {
    if (onPressBack) {
      onPressBack();
      return;
    }
    if (isMobileWeb && isNextJsApp && !goBack) {
      popRightModal();
    } else {
      onPressBack ? onPressBack() : Navigator.emit({ event: "goBack" });
    }
  };

  const onPressFilterIcon = () => {
    if (props.onPressFilterIcon) {
      props.onPressFilterIcon();
    } else {
      Navigator.emit({
        event: "modal",
        params: {
          screenName: "ALL_FILTERS_MODAL",
        },
      });
    }
  };

  const onPressHomeIcon = () => {
    if (isMobileWeb && isNextJsApp) {
      hideRightModal();
      Navigator.emit({
        event: "push",
        params: {
          nextUrl: "/",
          screenName: "HOME_SCREEN",
        },
      });
    } else if (isWeb) {
      Navigator.emit({
        event: "popToTop",
        params: { screenName: "HOME_SCREEN" },
      });
      Navigator.emit({
        event: "switchTab",
        params: { tabName: "HOME" },
      });
    } else {
      Navigator.emit({
        event: "popToTop",
        params: { tabName: "HOME" },
      });

      Navigator.emit({
        event: "push",
        params: {
          nextUrl: "/",
          screenName: "HOME_SCREEN",
        },
      });
    }
  };

  return (
    <View
      style={{
        paddingTop: insets.top,
        backgroundColor: theme.appBackgroundColor,
      }}
    >
      <View>
        <View
          style={{
            width: "100%",
            height: 72,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            borderBottomWidth: 1,
            borderBottomColor: borderBottomColor
              ? borderBottomColor
              : theme.grey,
          }}
        >
          {leftIcon ? (
            <TouchableWithoutFeedback
              onPress={onPressLeftIcon}
              style={{ cursor: "pointer" }}
            >
              <View
                style={{
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingLeft: theme.primaryPadding,
                  paddingRight: 5,
                }}
              >
                {leftIcon}
              </View>
            </TouchableWithoutFeedback>
          ) : (
            <TouchableWithoutFeedback onPress={onBack}>
              <View
                style={{
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingLeft: theme.primaryPadding,
                  paddingRight: 5,
                  cursor: "pointer",
                }}
              >
                {!isDesktop || showBack ? (
                  <PrestoIcon
                    icon={
                      <svgs.BackIcon
                        fillColor={theme.black}
                        width={35}
                        height={35}
                      />
                    }
                  />
                ) : null}
              </View>
            </TouchableWithoutFeedback>
          )}
          <View style={{ flex: 1, marginTop: 4 }}>
            <PrestoText
              fontStyle="600.semibold"
              size={theme.h3FontSize}
              extraStyle={{ ...props.titleExtraStyle }}
            >
              {title}
            </PrestoText>
          </View>
          <View
            style={{
              position: "absolute",
              right: 0,
              flexDirection: "row",
              alignItems: "center",
              height: 44,
              paddingRight: theme.primaryPadding,
            }}
          >
            {props?.CartIcon || showCartIcon ? (
              <TouchableWithoutFeedback onPress={onPressCart}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    flex: 1,
                    alignItems: "center",
                    marginRight: 10,
                  }}
                >
                  <PrestoIcon
                    icon={
                      props?.CartIcon || (
                        <svgs.ItemsAddedBagIcon width="35" height="35" />
                      )
                    }
                  />
                  {count ? (
                    <View
                      style={{
                        backgroundColor: theme.secondary,
                        borderRadius: 100,
                        width: 24,
                        height: 24,
                        position: "absolute",
                        left: 18,
                        top: -2,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <PrestoText
                        fontStyle="400.normal"
                        size={theme.h5FontSize}
                        color={theme.white}
                        extraStyle={{
                          marginTop: Platform.OS === "ios" ? 1 : 3,
                        }}
                      >
                        {count}
                      </PrestoText>
                    </View>
                  ) : null}
                </View>
              </TouchableWithoutFeedback>
            ) : null}
            {props?.showSortIcon ? (
              <Pressable onPress={() => props?.onPressSortIcon()}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <PrestoIcon
                    icon={
                      <svgs.SortIcon2
                        color2={props.sortIconColor || "#000"}
                        width="35"
                        height="35"
                      />
                    }
                  />
                </View>
              </Pressable>
            ) : null}
            {props?.showSelectedSortIcon ? (
              <Pressable onPress={() => props?.onPressSortIcon()}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <PrestoIcon
                    icon={
                      <svgs.SortSelected color2="#000" width="35" height="35" />
                    }
                  />
                </View>
              </Pressable>
            ) : null}
            {showFilterIcon ? (
              <Pressable onPress={() => onPressFilterIcon()}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <PrestoIcon
                    icon={<svgs.SortFilterIcon width="35" height="35" />}
                  />
                </View>
              </Pressable>
            ) : null}
            {showSelectedFilterIcon ? (
              <Pressable onPress={() => onPressFilterIcon()}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <PrestoIcon
                    icon={
                      <svgs.SortSelectedFilterIcon
                        color2={props.SortSelectedFilterColor || null}
                        width="35"
                        height="35"
                      />
                    }
                  />
                </View>
              </Pressable>
            ) : null}
            {showHomeIcon ? (
              <Pressable onPress={() => onPressHomeIcon()}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <PrestoIcon
                    icon={
                      <svgs.HomeIcon
                        width="35"
                        height="35"
                        color={theme.black}
                      />
                    }
                  />
                </View>
              </Pressable>
            ) : null}
          </View>
        </View>
      </View>
    </View>
  );
}
