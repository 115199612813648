import React, { useContext } from "react";
import { View } from "react-native";
import CheckboxRowButton from "@presto-components/CheckBoxes/CheckboxRowButton";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import _ from "lodash";
import utils from "../../utils";
import config from "@presto-common/config";
import KeyValueList from "@presto-components/KeyValueList/KeyValueList";

export default function PaymentOptions({
  address,
  onCheckboxClicked,
  paymentOption,
  wallet,
  cart,
}) {
  const { theme } = useContext(ThemeContext);
  const { max_points_to_use, min_points } = config;
  const max_price = (cart.total_price * max_points_to_use) / 100;
  const amount = Math.min(max_price, wallet?.current_balance / 100);

  const info = {
    [I18n.t(
      "screen_messages.payments.bill_amount"
    )]: utils.formattedCurrencyToRoundOff(
      cart.total_price || 0,
      I18n.t("screen_messages.common.currency"),
      true
    ),
    [I18n.t("screen_messages.payments.payment_via_points")]: amount.toFixed(2),
    [I18n.t(
      "screen_messages.payments.balance_payable"
    )]: utils.formattedCurrencyToRoundOff(
      cart.total_price - amount * 100 || 0,
      I18n.t("screen_messages.common.currency"),
      true
    ),
  };

  return (
    <>
      <CheckboxRowButton
        title={I18n.t("screen_messages.cash_on_delivery")}
        // description={
        //   address?.city == "Lusaka"
        //     ? I18n.t("screen_messages.payments.available_payment_options")
        //     : I18n.t(
        //         "screen_messages.payments.available_payment_options_for_inter_city"
        //       )
        // }
        showDisclosure={true}
        selected={paymentOption === "COD"}
        onPress={() => onCheckboxClicked("COD")}
      />
      <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
      {wallet?.current_balance / 100 > 0 ? (
        <>
          <CheckboxRowButton
            title={I18n.t("screen_messages.payments.pay_with_points")}
            description={`${I18n.t("screen_messages.points_available")}${
              Math.round(wallet?.current_balance / 100) > 0
                ? utils.formattedPriceRoundOff.call(
                    { currency: "" },
                    wallet?.current_balance / 100,
                    false
                  )
                : "0"
            } ${I18n.t("screen_messages.common.pts")} \n${I18n.t(
              "screen_messages.pay_via_points"
            )} ${max_price.toFixed(2)}`}
            showDisclosure={true}
            selected={paymentOption === "WALLET"}
            onPress={() => onCheckboxClicked("WALLET")}
          />
          {paymentOption === "WALLET" ? (
            <View style={{ paddingHorizontal: 15, paddingBottom: 10 }}>
              <KeyValueList
                rowDetailNumber={1}
                rowDis={`*${I18n.t("screen_messages.maximum_of_bill_amount", {
                  percent: max_points_to_use * 100,
                })}`}
                noLastDivider={true}
                showDivider={false}
                dict={info}
              />
            </View>
          ) : (
            <></>
          )}
          <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
        </>
      ) : (
        <></>
      )}
      <CheckboxRowButton
        title={I18n.t("screen_messages.payments.cards_comming_soon")}
        showDisclosure={false}
        selected={false}
      />
      <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
    </>
  );
}
