import React, { useReducer, useContext } from "react";
import { StyleSheet, View } from "react-native";
import LoadingContainer from "@presto-components/LoadingContainer";
import ThemeContext from "@presto-contexts/ThemeContext";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import UserContext from "@presto-contexts/UserContext";
import ProfileManager from "@presto-services/features/profile/ProfileManager";
import Utils from "@presto-services/common/Utils";
import _ from "lodash";
import I18n from "i18n-js";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import { VerticalSpacing } from "@presto-components/Spacing";
import PrestoText from "@presto-components/PrestoText";
import UserObject from "@presto-services/data_models/UserObject";
import TitleHeaderWithBackAndRightIcons from "@presto-components/Headers/TitleHeaderWithBackAndRightIcons";
import ModalContext from "@presto-contexts/ModalContext";
import utils from "../../utils";
import { prestoAlert } from "../../common/Alert";

function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_LOADING":
      return { ...state, loading: payload };
    case "SET_NAME":
      return { ...state, name: payload };
    case "SET_EMAIL":
      return { ...state, email: payload };
    case "SET_FOCUSED_ITEM":
      return { ...state, focusedItem: payload };
  }
}

export default function MissingInformation(props) {
  const { lightTheme, onGoBack } = props.route.params || [];

  const { theme } = useContext(ThemeContext);
  const styles = getStyles(theme);
  const { Navigator } = useContext(NavigatorContext);
  const { user, refreshUser } = useContext(UserContext);
  const isMobileWeb = utils.isMobileWeb();
  const isNextJsApp = utils.isNextJsApp();
  const { popRightModal, hideRightModal } = useContext(ModalContext);
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    name: "",
    email: "",
    focusedItem: null,
  });
  const reducerMethodGenerator = Utils.generateReducerMethod(dispatch);

  const { name, email, focusedItem } = state;
  const [setLoading, setName, setEmail, setFocusedItem] = _.map(
    ["SET_LOADING", "SET_NAME", "SET_EMAIL", "SET_FOCUSED_ITEM"],
    reducerMethodGenerator
  );

  const getOnFocusBorderColor = (field) => {
    return focusedItem === field ? theme.primary : "#CCCCCC";
  };

  const goBack = () => {
    if (onGoBack) {
      onGoBack();
      return;
    }
    if (isMobileWeb && isNextJsApp) {
      popRightModal();
    } else if (utils.isWeb() && window.next) {
      Navigator.emit({ event: "goBack" });
      popRightModal();
    } else {
      setTimeout(() => {
        Navigator.emit({ event: "goBack" });
      }, 100);
    }
  };

  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };

  const onSave = () => {
    const onSuccess = (response) => {
      console.log("response :>> ", JSON.stringify(response));
      setLoading(false);
      refreshUser();
      goBack();
      alert("Information", I18n.t("screen_messages.profile.update_message"));
    };

    const onError = (error) => {
      setLoading(false);
      console.log("onSave Error", error);
      alert(I18n.t("screen_messages.common.sorry_text"), error.message);
    };

    setLoading(true);

    let userObject = new UserObject();
    userObject.buildObject({
      ...user,
      name: name,
      email: email,
    });
    ProfileManager.updateProfile(userObject, onSuccess, onError);
  };

  const getTextInputStyles = (field) => {
    return {
      borderWidth: 2,
      borderColor: getOnFocusBorderColor(field),
      textInputPaddingHorizontal: 15,
      textInputColor: theme.textInputColor,
    };
  };

  return (
    <LoadingContainer bgColor={theme.appBackgroundColor} loading={false}>
      <View>
        {utils.isDesktop() ? (
          <View>
            <PrestoText
              fontStyle="600.semibold"
              color={theme.title}
              size={18}
              extraStyle={{ padding: 20, paddingBottom: 0 }}
            >
              {I18n.t("screen_messages.missing_info.title")}
            </PrestoText>
          </View>
        ) : (
          <TitleHeaderWithBackAndRightIcons
            title={I18n.t("screen_messages.missing_info.title")}
            onPressBack={goBack}
          />
        )}
      </View>
      <VerticalSpacing size={20} />
      <PrestoText
        fontStyle="400.normal"
        color={theme.subHeader}
        extraStyle={{ paddingHorizontal: 20 }}
      >
        {I18n.t("screen_messages.missing_info.discription")}
      </PrestoText>
      <VerticalSpacing size={30} />
      <View style={styles.container}>
        <PrestoText fontStyle="600.semibold" color={theme.subHeader}>
          {I18n.t("screen_messages.common.name_text")}
        </PrestoText>
        <VerticalSpacing size={10} />
        <PrestoTextInput
          style={getTextInputStyles("name")}
          theme={theme}
          placeholder={I18n.t("screen_messages.missing_info.enter_name")}
          value={name}
          onChangeText={setName}
          backgroundColor={lightTheme ? theme.white : theme.lightGrey}
          borderWidth={2}
          textContentType={"name"}
          autoCompleteType={"name"}
          importantForAutofill={"yes"}
          textAlignVertical="center"
          onFocus={() => setFocusedItem("name")}
          onBlur={() => setFocusedItem(null)}
        />
        <VerticalSpacing size={20} />
        <PrestoText fontStyle="600.semibold" color={theme.subHeader}>
          {I18n.t("screen_messages.common.email_address")}
        </PrestoText>
        <VerticalSpacing size={10} />
        <PrestoTextInput
          style={getTextInputStyles("email")}
          theme={theme}
          placeholder={I18n.t("screen_messages.missing_info.enter_email")}
          value={email}
          keyboardType={"email-address"}
          onChangeText={setEmail}
          backgroundColor={lightTheme ? theme.white : theme.lightGrey}
          borderWidth={2}
          textContentType={"email"}
          autoCompleteType={"email"}
          importantForAutofill={"yes"}
          textAlignVertical="center"
          onFocus={() => setFocusedItem("email")}
          onBlur={() => setFocusedItem(null)}
        />
      </View>
      <View style={styles.footerContainer}>
        <View style={styles.footerInner}>
          <View style={{ width: "50%", paddingRight: 5 }}>
            <PrestoSolidButton
              size="large"
              buttonStyle="tertiary"
              bgColor={theme.grey}
              title={I18n.t("screen_messages.common.cancel_text")}
              onPress={goBack}
            />
          </View>
          <View style={{ width: "50%", paddingLeft: 5 }}>
            <PrestoSolidButton
              size="large"
              buttonStyle="primary"
              title={I18n.t("screen_messages.common.continue_text")}
              onPress={onSave}
            />
          </View>
        </View>
      </View>
    </LoadingContainer>
  );
}

function getStyles(theme) {
  return {
    container: {
      paddingHorizontal: 20,
      height: "100%",
      width: "100%",
    },
    footerContainer: {
      paddingHorizontal: 20,
      position: "absolute",
      bottom: 30,
      width: "100%",
    },
    footerInner: {
      flexDirection: "row",
      flex: 1,
    },
  };
}
